<template>
    <v-textarea
        :value="value"
        :name="name"
        :label="labelText"
        :error-messages="errorMessages"
        :rows="1"
        :disabled="disabled"
        :auto-grow="autoGrow"
        :full-width="fullWidth"
        @input="$emit('input', $event)"
    ></v-textarea>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        fullWidth: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
        autoGrow: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style lang="scss">
.v-text-field--filled .v-label,
.v-text-field--full-width .v-label {
    top: auto;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: auto;
}
</style>
